export default function SocialButton(props) {
    return (
        <div className="m-2">
            <a href={props.href} target="_blank" rel="noopener noreferrer" className="social-section">
                <div className="fs-5">
                    <i className={"bi bi-" + props.logoName}></i> · {props.username}
                </div>
            </a>
        </div>
    );
}
