import React, { Component } from "react";
import Defaults from "../defaults";
import { Link } from "react-router-dom";
import SectionHeader from "../components/typography/headers/SectionHeader";
import ElegantAnchor from "../components/typography/links/ElegantAnchor";
import ElegantLink from "../components/typography/links/ElegantLink";
import SocialButton from "../components/buttons/SocialButton1";

class Index extends Component {
    state = {};

    componentDidMount() {
        Defaults.setDocumentTitle("Home");
    }

    render() {
        return (
            <div className="container">
                <section className="row mt-4 section-gap">
                    <section className="col-lg-9">
                        <div className="text-end">
                            <h1 className="display-1 mb-4" style={{ fontFamily: "inherit" }}>
                                <span className="text-brand-em-strong">Christopher Digno</span>
                            </h1>
                            <p className="fs-4 mb-0">
                                Hi! I am <span className="text-brand-em">Christopher Digno</span>, also known as my alias,{" "}
                                <span className="text-brand-em">Stackofsugar</span>. I'm a backend-focused software engineer and a second-year
                                computer science student. I'm a self-learner who's eager to keep learning about things I love about computer science.
                            </p>
                        </div>
                    </section>
                    <section className="col d-none d-lg-block">
                        <div className="">
                            <img className="img-cover rounded" alt="Never gonna give you up" src={Defaults.imageAsset("author-image", "jpg")}></img>
                        </div>
                    </section>
                </section>
                <section className="row section-gap">
                    <section className="col-xxl-7 col-xl-8 col-lg-12 section-gap-md-down">
                        <SectionHeader>Professional Summary</SectionHeader>
                        <p className="fs-5 mb-1">
                            I am a fifth-semester computer science student in Department of Informatics, Faculty of Information Technology and Data
                            Science, Sebelas Maret University. I enrolled for this study with one particular goal in mind, being a great software
                            developer. So far, I have completed many projects of the field, ranging from my C++ game project, my clinic management
                            website, to this front-end ReactJS website. All of my projects are accessible through my{" "}
                            <ElegantAnchor href="https://github.com/stackofsugar" blank="true">
                                GitHub Repository
                            </ElegantAnchor>{" "}
                            or my <ElegantLink to={"/portfolio"}>Portfolio Page.</ElegantLink>
                        </p>
                        <p className="fs-5 mb-0">
                            Currently, I'm focusing on improving my skills on web and backend development field. I have enrolled in{" "}
                            <ElegantAnchor href="https://startupcampus.id/" blank="true">
                                Startup Campus
                            </ElegantAnchor>{" "}
                            Backend Engineer Bootcamp for the second semester of 2022, where my backend skills will be further developed.
                        </p>
                    </section>
                    <section className="col">
                        <SectionHeader rootClass="mb-2">My Handles</SectionHeader>
                        <div className="d-flex flex-wrap">
                            <SocialButton logoName="envelope" username="digno.christopher@gmail.com" href="mailto:digno.christopher@gmail.com" />
                            <SocialButton logoName="linkedin" username="Christopher Digno" href="https://www.linkedin.com/in/christopherdigno" />
                            <SocialButton logoName="github" username="stackofsugar" href="https://github.com/stackofsugar" />
                            <SocialButton logoName="instagram" username="@drowned.axolotl" href="https://www.instagram.com/drowned.axolotl" />
                            <SocialButton logoName="telegram" username="@stackofsugar" href="https://t.me/stackofsugar" />
                        </div>
                    </section>
                </section>
                <section className="section-gap">
                    <SectionHeader>Website Development</SectionHeader>
                    <p className="fs-5">This website is a work in progress.</p>
                </section>
            </div>
        );
    }
}

export default Index;
