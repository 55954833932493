export default function ElegantAnchor(props) {
    let target = "_self";
    let rel = "";
    if (props.blank === "true") {
        rel = "noopener noreferrer";
        target = "_blank";
    }

    return (
        <a href={props.href} target={target} rel={rel} className="link-elegant-custom">
            {props.children}
        </a>
    );
}
